import React from "react"
import {
  Link as GatsbyLink,
  StaticQuery,
  graphql,
  useStaticQuery,
} from "gatsby"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import {
  Toolbar,
  Link,
  Typography,
  Box,
  Container,
  Card,
  CardMedia,
  CardContent,
  Grid,
} from "@material-ui/core"

import Layout from "../components/layout"
import Wrapper from "../wrapper/caseStudyWrapper"

const StyledToolbar = withStyles({
  root: {
    background: "#031242",
  },
})(Toolbar)

function BlogPage() {
  const classes = useStyles()

  return (
    <Wrapper>
      <StyledToolbar />
      <Container>
        <Box pt={5}>
          <Typography variant="h4" gutterBottom color="primary">
            <b>Case Studies</b>
          </Typography>
          {/* <Box display="flex" justifyContent="flex-end">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/">
                Blog
              </Link>
              <Link color="inherit" href="/getting-started/installation/">
                Article
              </Link>
              <Typography color="textPrimary">Blog Item 1</Typography>
            </Breadcrumbs>
          </Box> */}
        </Box>
        <Box py={5}>
          <Grid container spacing={6} alignItems="stretch">
            <StaticQuery
              query={graphql`
                query {
                  allStrapiCasestudy(filter: { publish: { eq: true } }) {
                    edges {
                      node {
                        slug
                        title
                        description
                        featured_image {
                          publicURL
                        }
                        tags {
                          name
                        }
                        created_at
                      }
                    }
                  }
                }
              `}
              render={data => {
                if (data.allStrapiCasestudy.edges.length === 0)
                  return (
                    <Grid item xs={12}>
                      No articles yet
                    </Grid>
                  )
                return (
                  <>
                    {data.allStrapiCasestudy.edges
                      .sort(
                        (a, b) =>
                          new Date(b.node.created_at) -
                          new Date(a.node.created_at)
                      )
                      .map(item => {
                        return (
                          <Grid item xs={12} md={4}>
                            <Card style={{ height: "100%" }}>
                              <CardMedia
                                className={classes.cardMedia}
                                image={item.node.featured_image?.publicURL}
                                component={GatsbyLink}
                                to={"/case-studies/" + item.node.slug}
                              />
                              <CardContent>
                                {item.node.tags.map(tag => (
                                  <Box component="span" mr={2}>
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                      gutterBottom
                                    >
                                      {tag.name}
                                    </Typography>
                                  </Box>
                                ))}

                                <Typography
                                  variant="h5"
                                  color="primary"
                                  gutterBottom
                                >
                                  <Link
                                    color="inherit"
                                    underline="none"
                                    component={GatsbyLink}
                                    to={"/case-studies/" + item.node.slug}
                                  >
                                    <b>{item.node.title}</b>
                                  </Link>
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {new Date(
                                    item.node.created_at
                                  ).toLocaleString("en-US", {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  })}
                                  {/* <AccessTimeIcon
                                    fontSize="small"
                                    style={{ paddingRight: 5 }}
                                  /> */}
                                </Typography>
                                <Typography variant="subtitle1">
                                  {item.node.description}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        )
                      })}
                  </>
                )
              }}
            />
          </Grid>
        </Box>
      </Container>
    </Wrapper>
  )
}

const useStyles = makeStyles(theme => ({
  cardMedia: {
    height: 0,
    paddingTop: "56.25%",
  },
}))

export default BlogPage
